import React, {useState, useEffect} from 'react'
import Header from './Header'
import Footer from './Footer'
import { addData } from '../Servicescalls';

function Faq() {
  const [activeTab, setActiveTab] = useState(0);
  const handleTabClick = (tabIndex) => {
    setActiveTab(tabIndex);
  }; 

  const [faq, setFaqs]=useState([])
  const GetAllFaq = async () => {
    const resonse = await addData("faq/getAllFaqs", {})
    var _data = resonse
    setFaqs(_data.data.faqs)
  }


useEffect(() => {
  GetAllFaq()
}, [])

  return (
    <div>
        <Header />
        <div className="cat-main-wrapper">
        {/* Page Title Section */}
        <section className="cat-page-title-section">
          <div className="container">
            <div className="row">
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <div className="cat-page-title">
                  <h1>Faq's</h1>
                  <ul>
                    <li>
                      <a href="#">Home</a>
                    </li>
                    <li>
                      Faq's
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Faq Section */}
        <section className="cat-faq-wrapper cat-section-spacer">
          <div className="container">
            <div className="row">
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
              </div>
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <div className="cat-faq-page-ques">
                  <div className="accordion cat-faq-wrapper" id="accordionQuestions">
                    {faq.map((data, key)=>( 
                    <div key={key} className="accordion-item">
                      {/* <h2 className="accordion-header" id="headingOne"> */}
                        <a onClick={() => handleTabClick(key)} className="accordion-button collapsed p-2" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                          {data.question}
                        </a>
                      {/* </h2> */}
                      <div id="collapseOne"  className={`accordion-collapse collapse ${activeTab === key ? 'show' : ''}`} aria-labelledby="headingOne" data-bs-parent="#accordionQuestions">
                        <div className="accordion-body">
                         {data.answer}
                        </div>
                      </div>
                    </div>
                  ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
        <Footer />
    </div>
  )
}

export default Faq