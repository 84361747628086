import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom";
import { addData, adminaddData } from '../Servicescalls';
import toast, { Toaster } from 'react-hot-toast';

function Header() {
  const [languagess, setlanguagess] = useState("en");
  sessionStorage.setItem("languagedir", languagess)
  const [sticky, setSticky] = useState("");
  const [visible, setvisible] = useState(true);
  const [visible1, setvisible1] = useState(false);
  const [form, setform] = useState(
    { selectState: "", selectCity: "", eventType: "", numberOfPeople: "", itemType: "", enterYourName: "", enterYourEmail: "", enterContactNumber: "", date: "", description: "", }
  );
  const [statess, setStates] = useState([])

  const sidebarclick = () => {
    setvisible1(!visible1)
  }

  const handleChange = (e) => {
    let myUser = { ...form };
    myUser[e.target.name] = e.target.value;
    setform(myUser);
  };
  // Add function

  const Getallstates = async () => {
    const resonse = await adminaddData("state/getall")
    var _data = resonse
    setStates(_data.data.states)

  }

  // Add function

  const addenquary = async (e) => {
    e.preventDefault()
    const dataArray = new FormData();
    dataArray.append("selectState", form.selectState);
    dataArray.append("selectCity", form.selectCity);
    dataArray.append("eventType", form.eventType);
    dataArray.append("numberOfPeople", form.numberOfPeople);
    dataArray.append("itemType", form.itemType);
    dataArray.append("enterYourName", form.enterYourName);
    dataArray.append("enterYourEmail", form.enterYourEmail);
    dataArray.append("enterContactNumber", form.enterContactNumber);
    dataArray.append("date", form.date);
    dataArray.append("description", form.description);

    try {
      const resonse = await addData("enquiry/addenquiry", dataArray)
      var _data = resonse
      console.log(_data)
      toast.success(_data.data.message)
      setform({ selectState: "", selectCity: "", eventType: "", numberOfPeople: "", itemType: "", enterYourName: "", enterYourEmail: "", enterContactNumber: "", date: "", description: "", })
      closeModal();
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message)
      } else {
        toast.error("An error occurred. Please try again.")
      }
    }
  }

  const closeModal = () => {
    const modalElement = document.getElementById("exampleModal");
    const closeButton = modalElement.querySelector(".close");
    modalElement.classList.remove("show");
    modalElement.setAttribute("aria-modal", "false");
    modalElement.setAttribute("style", "display: none");
    document.body.classList.remove("modal-open");
    closeButton.click();
  };
  // useEffect(() => {
  //   window.addEventListener("scroll", isSticky);
  //   return () => {
  //     window.removeEventListener("scroll", isSticky);
  //   };


  // }, []);

  // const isSticky = () => {
  //   const scrollTop = window.scrollY;
  //   const stickyClass = scrollTop >= 100 ? "is-sticky bg-dark-1" : "";
  //   const visible = scrollTop >= 100 ? "is-sticky bg-dark-1" : "";
  //   setSticky(stickyClass);
  //   setvisible(visible);
  // };

  // const classes = `header  js-header ${sticky}`;

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const [eventss, seteventss] = useState([])
  const GetAllDetails = async () => {
    const bodydata = {
      eventId: "All"
    }
    const resonse = await addData("home/getHomeItems", bodydata)
    var _data = resonse
    seteventss(_data.data.events)
  }

  useEffect(() => {
    scrollToTop()
    Getallstates()
    GetAllDetails()
    const timer = setTimeout(() => {
      setvisible(false);
    }, 2000);
    return () => clearTimeout(timer);
  }, []);
  const tabvalueis = sessionStorage.getItem('tabvalue')
  const [activeItem, setActiveItem] = useState(tabvalueis || 'Home');

  const handleItemClick = (itemName) => {
    sessionStorage.setItem('tabvalue', itemName)
    setActiveItem(itemName);
  };

  return (
    <div>
      {visible ? (
        <div class="cat-preloader">
          <div class="cat-preloader-inner">
            <img src="../assets/images/main/loader.gif" alt="loader" />
          </div>
        </div>
      ) : null}
      <header>
        <div className="header-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-xl-2 col-lg-3 col-md-3 col-sm-4 col-4">
                <div className="cat-logo-wrapper">
                  <Link to="/" onClick={() => handleItemClick('Home')}>
                    <img src="../assets/images/main/logo.png" alt="" />
                  </Link>
                </div>
              </div>
              <div className="col-xl-10 col-lg-9 col-md-9 col-sm-8 col-8">
                <div className="main-menu-container">
                  {/* Header Menus */}
                  <div className={visible1 == true ? 'main-menu open-menu' : 'main-menu'}>
                    <ul>
                      <li className={activeItem === 'Home' ? 'active' : ''}>
                        <Link to="/" onClick={() => handleItemClick('Home')}>Home</Link>
                      </li>
                      <li className={activeItem === 'Events' ? 'active' : ''}>
                        <Link to="/events" onClick={() => handleItemClick('Events')}>Events</Link>
                      </li>
                      <li className={activeItem === 'About Us' ? 'active' : ''}>
                        <Link to="/about" onClick={() => handleItemClick('About Us')}>About Us</Link>
                      </li>
                      <li className={activeItem === 'Faq' ? 'active' : ''}>
                        <Link to="/faq" onClick={() => handleItemClick('Faq')}>Faq</Link>
                      </li>
                      <li className={activeItem === 'Contact Us' ? 'active' : ''}>
                        <Link to="/contact" onClick={() => handleItemClick('Contact Us')}>Contact Us</Link>
                      </li>
                    </ul>
                  </div>
                  <div className="menu-btn-wrap">
                    {/* <a href="javascript:void(0);" className="search-btn">
                      <img src="http://kamleshyadav.com/html/catering/catering/assets/images/main/search.svg" alt="" />
                    </a> */}
                    <a onClick={() => { sidebarclick() }} className="menu-btn">
                      <span />
                      <span />
                      <span />
                    </a>
                  </div>
                  <a
                    // onClick={toggleMenu}
                    type="button" className="cat-btn header-btn" data-toggle="modal" data-target="#exampleModal"
                  >
                    Get Free Quote
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <div className='menu-container1'>
        <a onClick={() => { scrollToTop() }} class="scroll-to-topp"><span class="fa fa-angle-up"></span></a>
      </div>
      <div>

        <div>

          <div className="modal fade" id="exampleModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-lg">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">Enquiry</h5>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body">
                  <section className="" id="bookNow">
                    <div className="container">
                      <form onSubmit={(e) => { addenquary(e) }}>
                        <div className="row">
                          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="cat-booking-form">
                              <span className="cat-shapp" />
                              <span className="cat-shapp2" />
                              <div className="cat-booking-from-inner">
                                <div className="row">
                                  <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                                    <div className="input-wrap">
                                      <select required name="selectState" value={form.selectState} onChange={(e) => { handleChange(e) }} id className="nice-selection">
                                        <option value="">Select State</option>
                                        {statess.map((data, key) => (
                                          <option key={key} value={data.title}>{data.title}</option>
                                        ))}
                                        {/* <option value={2}>UK</option>
                            <option value={3}>India</option> */}
                                      </select>
                                    </div>
                                  </div>
                                  <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                                    <div className="input-wrap">
                                      <input required name="selectCity" value={form.selectCity} onChange={(e) => { handleChange(e) }} type="text" placeholder="Enter Your City" />
                                    </div>
                                  </div>
                                  <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                                    <div className="input-wrap">
                                      <select required name="eventType" value={form.eventType} onChange={(e) => { handleChange(e) }} id className="nice-selection">
                                        <option value="">Event Type</option>
                                        {eventss.map((data, key) => (
                                          <option key={key} value={data.name}>{data.name}</option>
                                        ))}
                                      </select>
                                    </div>
                                  </div>
                                  <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                                    <div className="input-wrap">
                                      <select required name="numberOfPeople" value={form.numberOfPeople} onChange={(e) => { handleChange(e) }} id className="nice-selection">
                                        <option value="">No. of People</option>
                                        <option value="50_100">50 - 100</option>
                                        <option value="100_200">100 - 200</option>
                                        <option value="More">More</option>
                                        {/* <option value={3}>400-1000</option>
                            <option value={3}>1000-5000</option>
                            <option value={3}>5000 +</option> */}
                                      </select>
                                    </div>
                                  </div>
                                  <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                                    <div className="input-wrap">
                                      <select required name="itemType" value={form.itemType} onChange={(e) => { handleChange(e) }} id className="nice-selection">
                                        <option value="">Item Type</option>
                                        <option value="Vegetarian">Vegetarian</option>
                                        <option value="NonVegetarian">Non Vegeterian</option>
                                      </select>
                                    </div>
                                  </div>
                                  <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                                    <div className="input-wrap">
                                      <input required name="enterYourName" value={form.enterYourName} onChange={(e) => { handleChange(e) }} type="text" placeholder="Enter Your Name" />
                                    </div>
                                  </div>
                                  <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                                    <div className="input-wrap">
                                      <input required name="enterYourEmail" value={form.enterYourEmail} onChange={(e) => { handleChange(e) }} type="eamil" placeholder="Enter Your Email" />
                                    </div>
                                  </div>
                                  <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                                    <div className="input-wrap">
                                      <input required name="enterContactNumber" value={form.enterContactNumber} onChange={(e) => { handleChange(e) }} type="number" placeholder="Enter Contact No." />
                                    </div>
                                  </div>
                                  <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                                    <div className="input-wrap">
                                      <input min={new Date().toISOString().split('T')[0]} required name="date" value={form.date} onChange={(e) => { handleChange(e) }} type="date" placeholder="Select Date" />
                                    </div>
                                  </div>
                                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                    <div className="cat-input-wrap">
                                      <textarea required name="description" value={form.description} onChange={(e) => { handleChange(e) }} id style={{ padding: '16px' }} placeholder="Note your discussion" defaultValue={""} />
                                    </div>
                                  </div>

                                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 text-right mt-3">
                                    <button type='submit' className="cat-btn">
                                      Send Request
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </section>
                </div>
                {/* <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                <button type="button" className="btn btn-primary">Save changes</button>
              </div> */}
              </div>
            </div>
          </div>
        </div>
        <Toaster />
      </div>
    </div>
  )
}

export default Header