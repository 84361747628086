import React from "react";
import Header from "./Header";
import Footer from "./Footer";

function About() {
  return (
    <div>
      <Header />
      <div className="cat-main-wrapper">
        {/* Page Title Section */}
        <section className="cat-page-title-section">
          <div className="container">
            <div className="row">
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <div className="cat-page-title">
                  <h1>About Us</h1>
                  <ul>
                    <li>
                      <a href="#">Home</a>
                    </li>
                    <li>About Us</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Story */}
        <section className="cat-about-wrapper cat-section-spacer">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                <div className="cat-story-img">
                  <img src="../assets/images/main/story.png" alt="" />
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                <div className="cat-story-info">
                  <div className="cat-heading-wrapper">
                    <h4>Our Story</h4>
                    <h2>Trusted By 200 + satisfied clients</h2>
                  </div>
                  <p className="mb-3">
                    Food Delivery! We are dedicated to bringing you delicious
                    meals right to your doorstep. Our mission is to provide
                    high-quality food and exceptional service. Whether you're
                    craving local cuisine or something more exotic, we have a
                    wide variety of options to satisfy your taste buds. Our team
                    is passionate about food and committed to ensuring your
                    dining experience is seamless and enjoyable. Thank you for
                    choosing Hasini Enterprises' Food Delivery
                  </p>
                  <ul className="cat-story-list">
                    <li>
                      <span>
                        <svg
                          enableBackground="new 0 0 512 512"
                          viewBox="0 0 512 512"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g>
                            <path d="m271.176 121.396c-150.205 7.822-271 132.495-271 284.604v106l37.925-88.29c44.854-89.692 133.847-147.041 233.075-152.314v121.318l240.648-196.714-240.648-196z" />
                          </g>
                        </svg>
                      </span>{" "}
                      Fresh and Fast food Delivery
                    </li>
                    <li>
                      <span>
                        <svg
                          enableBackground="new 0 0 512 512"
                          viewBox="0 0 512 512"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g>
                            <path d="m271.176 121.396c-150.205 7.822-271 132.495-271 284.604v106l37.925-88.29c44.854-89.692 133.847-147.041 233.075-152.314v121.318l240.648-196.714-240.648-196z" />
                          </g>
                        </svg>
                      </span>{" "}
                      24/7 Customer Support
                    </li>
                    <li>
                      <span>
                        <svg
                          enableBackground="new 0 0 512 512"
                          viewBox="0 0 512 512"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g>
                            <path d="m271.176 121.396c-150.205 7.822-271 132.495-271 284.604v106l37.925-88.29c44.854-89.692 133.847-147.041 233.075-152.314v121.318l240.648-196.714-240.648-196z" />
                          </g>
                        </svg>
                      </span>{" "}
                      Easy Customization Options
                    </li>
                    <li>
                      <span>
                        <svg
                          enableBackground="new 0 0 512 512"
                          viewBox="0 0 512 512"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g>
                            <path d="m271.176 121.396c-150.205 7.822-271 132.495-271 284.604v106l37.925-88.29c44.854-89.692 133.847-147.041 233.075-152.314v121.318l240.648-196.714-240.648-196z" />
                          </g>
                        </svg>
                      </span>{" "}
                      Delicious Deals for Delicious Meals
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Video */}
        <section className="cat-video-wrapper has-bg">
          <div className="container-fluid">
            <div className="row align-items-center">
              <div className="col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12 p-0">
                <div className="cat-story-video cat-main-video">
                  <img
                    className="video-img"
                    src="../assets/images/main/banner-bg.jpg"
                    alt=""
                  />
                  <div className="cat-play-btn">
                    <a
                      className="popup-youtube"
                      rel="external"
                      href="https://www.youtube.com/watch?v=jKabrDJW2EU"
                    >
                      <i className="fa fa-play" aria-hidden="true" />
                    </a>
                    <div className="btn-wave" />
                  </div>
                </div>
              </div>
              <div className="col-xl-7 col-lg-7 col-md-12 col-sm-12 col-12">
                <div className="cat-counter-info">
                  <div className="cat-counter-wrap">
                    <div className="row">
                      <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-6 counter1">
                        <div className="cat-counter-section">
                          <div className="cat-counter-section-inner">
                            <span>
                              <img
                                src="https://kamleshyadav.com/html/catering/html/assets/images/main/c1.svg"
                                alt=""
                              />
                            </span>
                            <h4>786</h4>
                            <p>Happy Customers</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-6 counter2">
                        <div className="cat-counter-section">
                          <div className="cat-counter-section-inner">
                            <span>
                              <img
                                src="https://kamleshyadav.com/html/catering/html/assets/images/main/c2.svg"
                                alt=""
                              />
                            </span>
                            <h4>109</h4>
                            <p>Expert Chefs</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-6 counter3">
                        <div className="cat-counter-section">
                          <div className="cat-counter-section-inner">
                            <span>
                              <img
                                src="https://kamleshyadav.com/html/catering/html/assets/images/main/c3.svg"
                                alt=""
                              />
                            </span>
                            <h4>23</h4>
                            <p>Years Of Experience</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-6 counter4">
                        <div className="cat-counter-section">
                          <div className="cat-counter-section-inner">
                            <span>
                              <img
                                src="https://kamleshyadav.com/html/catering/html/assets/images/main/c4.svg"
                                alt=""
                              />
                            </span>
                            <h4>235</h4>
                            <p>Events Completed</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Mobile App Section */}
        <section className="cat-mobile-section cat-section-spacer-equal relative">
          <div className="top-left-shap bouncing">
            <img src="../assets/images/main/shap/03.png" alt="" />
          </div>
          <div className="top-right-shap bouncing">
            <img src="../assets/images/main/shap/01.png" alt="" />
          </div>
          <div className="container">
            <div className="row">
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <div className="cat-heading-wrapper">
                  <h4>Website</h4>
                  <h2>Book With Our Website</h2>
                </div>
              </div>
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <div className="cat-monile-app-info">
                  <div className="cat-store-imgs">
                    <a
                      type="button"
                      className="cat-btn bookNow"
                      data-toggle="modal"
                      data-target="#exampleModal"
                    >
                      Get Quote
                    </a>
                    {/* <img src="../assets/images/main/playstore.png" alt="" />
                    <img src="../assets/images/main/apple.png" alt="" /> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
}

export default About;
