import React from "react";
import Header from "./Header";
import Footer from "./Footer";

function Faq() {
  return (
    <div>
      <Header />
      <div className="cat-main-wrapper">
        <section className="cat-page-title-section">
          <div className="container">
            <div className="row">
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <div className="cat-page-title">
                  <h1>Shipping Policy</h1>
                  <ul>
                    <li>
                      <a href="#">Home</a>
                    </li>
                    <li>Shipping Policy</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="cat-faq-wrapper cat-section-spacer">
          <div className="container">
            <div className="row">
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"></div>
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <div className="cat-faq-page-ques">
                  <h5>Delivery Areas </h5>
                  <p>
                    We deliver to specified areas within the city. Please check
                    our delivery area coverage before placing an order.
                  </p>
                  <h5 className="pt-4">Delivery Time</h5>
                  <p>
                    Orders are delivered within an estimated time frame.
                    Delivery times may vary based on traffic and weather
                    conditions.
                  </p>
                  <h5 className="pt-4">Delivery Charges</h5>
                  <p>
                    Delivery charges are applicable and will be displayed at
                    checkout.
                  </p>
                  <h5 className="pt-4">Order Tracking</h5>
                  <p>
                    You can track your order status through our website or app.
                  </p>
                  <h5 className="pt-4">Contact Information</h5>
                  <p>
                    For any shipping-related queries, please contact us at{" "}
                    <b>info@hasinienterprises.com</b>{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
}

export default Faq;
