import React from "react";
import Header from "./Header";
import Footer from "./Footer";

function Faq() {
  return (
    <div>
      <Header />
      <div className="cat-main-wrapper">
        {/* Page Title Section */}
        <section className="cat-page-title-section">
          <div className="container">
            <div className="row">
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <div className="cat-page-title">
                  <h1>Privacy Policy</h1>
                  <ul>
                    <li>
                      <a href="#">Home</a>
                    </li>
                    <li>Privacy Policy</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Faq Section */}
        <section className="cat-faq-wrapper cat-section-spacer">
          <div className="container">
            <div className="row">
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"></div>
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <div className="cat-faq-page-ques">
                  <h5>Information Collection </h5>
                  <p>
                    We collect personal information such as name, address,
                    email, and payment details when you create an account or
                    place an order.
                  </p>
                  <h5 className="pt-4">Use of Information</h5>
                  <p>
                    The information collected is used to process orders, improve
                    our services, and communicate with you regarding your orders
                    and our services.
                  </p>
                  <h5 className="pt-4">Data Security</h5>
                  <p>
                    We implement various security measures to protect your
                    personal information. However, no method of transmission
                    over the Internet is 100% secure.
                  </p>
                  <h5 className="pt-4">Cookies</h5>
                  <p>
                    Our website uses cookies to enhance user experience. You can
                    choose to disable cookies through your browser settings.
                  </p>
                  <h5 className="pt-4">Third-Party Services</h5>
                  <p>
                    We do not sell, trade, or otherwise transfer your personally
                    identifiable information to outside parties without your
                    consent, except to trusted third parties who assist us in
                    operating our website and conducting our business.
                  </p>
                  <h5 className="pt-4">Changes to Privacy Policy</h5>
                  <p>
                    We reserve the right to modify this privacy policy at any
                    time. Changes will be posted on this page.
                  </p>
                  <h5 className="pt-4">Contact Information</h5>
                  <p>
                    For any privacy-related concerns, please contact us at{" "}
                    <b>info@hasinienterprises.com</b>{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
}

export default Faq;
