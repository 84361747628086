import React, { useState, useEffect } from 'react'
import Header from './Header'
import Footer from './Footer'
import { addData, imgurl, adminaddData } from '../Servicescalls';
import toast, { Toaster } from 'react-hot-toast';
function Contact() {
  const [form, setform] = useState(
    { selectState: "", selectCity: "", eventType: "", numberOfPeople: "", itemType: "", enterYourName: "", enterYourEmail: "", enterContactNumber: "", date: "", description: "", }
  );
  const handleChange = (e) => {
    let myUser = { ...form };
    myUser[e.target.name] = e.target.value;
    setform(myUser);
  };
  const [statess, setStates] = useState([])
  const Getallstates = async () => {
    const resonse = await adminaddData("state/getall")
    var _data = resonse
    setStates(_data.data.states)
  }
  const [eventss, seteventss] = useState([])
  const GetAllDetails = async () => {
    const bodydata = {
      eventId: "All"
    }
    const resonse = await addData("home/getHomeItems", bodydata)
    var _data = resonse
    seteventss(_data.data.events)
  }
  useEffect(() => {
    Getallstates()
    GetAllDetails()
  }, [])

  const addenquary = async (e) => {
    e.preventDefault()
    const dataArray = new FormData();
    dataArray.append("selectState", form.selectState);
    dataArray.append("selectCity", form.selectCity);
    dataArray.append("eventType", form.eventType);
    dataArray.append("numberOfPeople", form.numberOfPeople);
    dataArray.append("itemType", form.itemType);
    dataArray.append("enterYourName", form.enterYourName);
    dataArray.append("enterYourEmail", form.enterYourEmail);
    dataArray.append("enterContactNumber", form.enterContactNumber);
    dataArray.append("date", form.date);
    dataArray.append("description", form.description);

    try {
      const resonse = await addData("enquiry/addenquiry", dataArray)
      var _data = resonse
      console.log(_data)
      toast.success(_data.data.message)
      setform({ selectState: "", selectCity: "", eventType: "", numberOfPeople: "", itemType: "", enterYourName: "", enterYourEmail: "", enterContactNumber: "", date: "", description: "", })
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message)
      } else {
        toast.error("An error occurred. Please try again.")
      }
    }
  }

  return (
    <div>
      <Header />
      <div className="cat-main-wrapper">
        {/* Page Title Section */}
        <section className="cat-page-title-section">
          <div className="container">
            <div className="row">
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <div className="cat-page-title">
                  <h1>Contact Us</h1>
                  <ul>
                    <li>
                      <a href="#">Home</a>
                    </li>
                    <li>
                      Contact Us
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Contact Form Section  */}
        <section className="cat-booking-wrapper mt-5 pt-3" id="bookNow">
          <div className="container">
            <form onSubmit={(e) => { addenquary(e) }}>
              <div className="row">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <div className="cat-booking-form">
                    <span className="cat-shapp" />
                    <span className="cat-shapp2" />
                    <div className="cat-booking-from-inner">
                      <div className="row">
                        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                          <div className="input-wrap">
                            <select required name="selectState" value={form.selectState} onChange={(e) => { handleChange(e) }} id className="nice-selection">
                              <option value="">Select State</option>
                              {statess.map((data, key) => (
                                <option key={key} value={data.title}>{data.title}</option>
                              ))}
                              {/* <option value={2}>UK</option>
                            <option value={3}>India</option> */}
                            </select>
                          </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                          <div className="input-wrap">
                            <input required name="selectCity" value={form.selectCity} onChange={(e) => { handleChange(e) }} type="text" placeholder="Enter Your City" />
                          </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                          <div className="input-wrap">
                            <select required name="eventType" value={form.eventType} onChange={(e) => { handleChange(e) }} id className="nice-selection">
                              <option value="">Event Type</option>
                              {eventss.map((data, key) => (
                                <option key={key} value={data.name}>{data.name}</option>
                              ))}

                            </select>
                          </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                          <div className="input-wrap">
                            <select required name="numberOfPeople" value={form.numberOfPeople} onChange={(e) => { handleChange(e) }} id className="nice-selection">
                              <option value="">No. of People</option>
                              <option value="50_100">50 - 100</option>
                              <option value="100_200">100 - 200</option>
                              <option value="More">More</option>
                              {/* <option value={3}>400-1000</option>
                            <option value={3}>1000-5000</option>
                            <option value={3}>5000 +</option> */}
                            </select>
                          </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                          <div className="input-wrap">
                            <select required name="itemType" value={form.itemType} onChange={(e) => { handleChange(e) }} id className="nice-selection">
                              <option value="">Item Type</option>
                              <option value="Vegetarian">Vegetarian</option>
                              <option value="NonVegetarian">Non Vegeterian</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                          <div className="input-wrap">
                            <input required name="enterYourName" value={form.enterYourName} onChange={(e) => { handleChange(e) }} type="text" placeholder="Enter Your Name" />
                          </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                          <div className="input-wrap">
                            <input required name="enterYourEmail" value={form.enterYourEmail} onChange={(e) => { handleChange(e) }} type="eamil" placeholder="Enter Your Email" />
                          </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                          <div className="input-wrap">
                            <input required name="enterContactNumber" value={form.enterContactNumber} onChange={(e) => { handleChange(e) }} type="number" placeholder="Enter Contact No." />
                          </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                          <div className="input-wrap">
                            <input min={new Date().toISOString().split('T')[0]} required name="date" value={form.date} onChange={(e) => { handleChange(e) }} type="date" placeholder="Select Date" />
                          </div>
                        </div>
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                          <div className="cat-input-wrap">
                            <textarea required name="description" value={form.description} onChange={(e) => { handleChange(e) }} id style={{ padding: '16px' }} placeholder="Note your discussion" defaultValue={""} />
                          </div>
                        </div>

                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 text-right mt-3">
                          <button type='submit' className="cat-btn">
                            Send Request
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </section>
        {/* Contact Info Section */}
        <section className="cat-service-wrapper cat-section-spacer has-bg relative">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
                <div className="cat-service-section">
                  <div className="cat-service-inner">
                    <div className="cat-service-img">
                      <img src="https://kamleshyadav.com/html/catering/html/assets/images/main/call.svg" alt="" />
                    </div>
                    <div className="cat-service-info">
                      <h4>Call Us</h4>
                      <p>
                        094946 13601
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
                <div className="cat-service-section">
                  <div className="cat-service-inner">
                    <div className="cat-service-img">
                      <img src="https://kamleshyadav.com/html/catering/html/assets/images/main/mail.svg" alt="" />
                    </div>
                    <div className="cat-service-info">
                      <h4>Mail Us</h4>
                      <p>
                        info@digitalraiz.com
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
                <div className="cat-service-section">
                  <div className="cat-service-inner">
                    <div className="cat-service-img">
                      <img src="https://kamleshyadav.com/html/catering/html/assets/images/main/location.svg" alt="" />
                    </div>
                    <div className="cat-service-info">
                      <h4>Location</h4>
                      <p>
                        K P H B Phase 1, Kukatpally,
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Google Map Section  */}
        <section className="cat-map-wrapper">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12 cat-map-section">
                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7929.383324299389!2d3.426551!3d6.433638!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x103bf52931db51f1%3A0xebccb6fc0bd61e40!2s19%20Engineering%20Close%2C%20Victoria%20Island%2C%20Lagos%2C%20Nigeria!5e0!3m2!1sen!2sin!4v1623326034824!5m2!1sen!2sin" width="100%" height={600} allowFullScreen loading="lazy" />
              </div>
            </div>
          </div>
        </section>
        
      </div>
      <Footer />
    </div>
  )
}

export default Contact