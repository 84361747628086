import React from "react";
import Header from "./Header";
import Footer from "./Footer";

function Faq() {
  return (
    <div>
      <Header />
      <div className="cat-main-wrapper">
        {/* Page Title Section */}
        <section className="cat-page-title-section">
          <div className="container">
            <div className="row">
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <div className="cat-page-title">
                  <h1>Terms & Conditions</h1>
                  <ul>
                    <li>
                      <a href="#">Home</a>
                    </li>
                    <li>Terms & Conditions</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Faq Section */}
        <section className="cat-faq-wrapper cat-section-spacer">
          <div className="container">
            <div className="row">
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"></div>
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <div className="cat-faq-page-ques">
                  <h5>Introduction </h5>
                  <p>
                    Welcome to Hasini Enterprises' Food Delivery. By using our
                    services, you agree to the following terms and conditions.
                    Please read them carefully.
                  </p>
                  <h5 className="pt-4">Eligibility</h5>
                  <p>
                    You must be at least 18 years old to use our services. By
                    using our platform, you represent and warrant that you meet
                    this age requirement.
                  </p>
                  <h5 className="pt-4">Account Registration</h5>
                  <p>
                    To place an order, you need to create an account. You are
                    responsible for maintaining the confidentiality of your
                    account information and for all activities under your
                    account.
                  </p>
                  <h5 className="pt-4">Ordering and Payment</h5>
                  <p>
                    All orders are subject to acceptance and availability.
                    Prices are subject to change without notice. Payment must be
                    made at the time of order via our accepted payment methods.
                  </p>
                  <h5 className="pt-4">Delivery</h5>
                  <p>
                    We aim to deliver within the estimated time frames, but
                    delays may occur due to unforeseen circumstances. We are not
                    liable for any delays.
                  </p>
                  <h5 className="pt-4">Cancellation and Refunds</h5>
                  <p>
                    Orders can be cancelled within a specific time frame (refer
                    to the Cancellation Policy below). Refunds will be processed
                    as per our Refund Policy.
                  </p>
                  <h5 className="pt-4">Use of the Website</h5>
                  <p>
                    You agree not to misuse the website. Any unauthorized use,
                    including but not limited to unauthorized access, will be
                    investigated and appropriate legal action may be taken.
                  </p>
                  <h5 className="pt-4">Intellectual Property</h5>
                  <p>
                    All content on this site, including text, graphics, logos,
                    and images, is the property of Hasini Enterprises or its
                    content suppliers and is protected by copyright laws.
                  </p>
                  <h5 className="pt-4">Limitation of Liability</h5>
                  <p>
                    Hasini Enterprises is not liable for any indirect,
                    incidental, or consequential damages arising out of or in
                    connection with the use of our services.
                  </p>
                  <h5 className="pt-4">Changes to Terms</h5>
                  <p>
                    We reserve the right to make changes to these terms at any
                    time. Your continued use of our services signifies your
                    acceptance of any changes.{" "}
                  </p>

                  <h5 className="pt-4">Contact Information</h5>
                  <p>
                    {" "}
                    For any queries, please contact us at{" "}
                    <b>info@hasinienterprises.com</b>{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
}

export default Faq;
