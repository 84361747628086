import React, { useState, useEffect } from "react";
import { addData, imgurl } from "../Servicescalls";

function Footer() {
  const [contactus, setcontactus] = useState([]);
  const [gallery, setgallery] = useState([]);
  const [eventss, seteventss] = useState([]);

  const GetAllDetails = async () => {
    const bodydata = {
      eventId: "All",
    };
    const resonse = await addData("home/getHomeItems", bodydata);
    var _data = resonse;
    setcontactus(_data.data.contactus[0]);
    setgallery(_data.data.socialgallery);
    seteventss(_data.data.events);
  };

  useEffect(() => {
    GetAllDetails();
  }, []);

  return (
    <div>
      <footer className="main-footer">
        <div className="cat-footer-wrapper relative">
          <div className="top-left-shap bouncing">
            <img src="../assets/images/main/shap/02.png" alt="" />
          </div>
          <div className="top-right-shap bouncing">
            <img src="../assets/images/main/shap/04.png" alt="" />
          </div>
          <div className="container">
            <div className="row">
              <div className="col-xl-3 col-lg-3 col-md-6 col-12">
                <div className="cat-widget cat-foot-about">
                  <h4 className="footer-title">About Catering</h4>
                  <p>
                    Welcome to Hasini Enterprises' Food Delivery! We are
                    dedicated to bringing you delicious meals right to your
                    doorstep.
                  </p>
                  <ul className="cat-footer-social">
                    <li>
                      <a href={contactus.facebook}>
                        <i className="fa fa-facebook-f" />
                      </a>
                    </li>
                    <li>
                      <a href={contactus.twitter}>
                        <i className="fa fa-twitter" />
                      </a>
                    </li>
                    <li>
                      <a href={contactus.instagram}>
                        <i className="fa fa-instagram" />
                      </a>
                    </li>
                    <li>
                      <a href={contactus.youtube}>
                        <i className="fa fa-youtube-play" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-6 col-12">
                <div className="cat-widget cat-foot-menu">
                  <h4 className="footer-title">Special Facilities</h4>
                  <div className="footer-menu">
                    <ul>
                      {eventss.map((data, key) => (
                        <li>
                          <a href="/events" key={key}>
                            <span>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                x="0px"
                                y="0px"
                                width={28}
                                height={18}
                                viewBox="0 0 28 18"
                                xmlSpace="preserve"
                              >
                                <path d="M27.6,8.5c-5.5,0-9.5-8.1-9.5-8.2C17.9,0,17.7,0,17.5,0.1c-0.2,0.1-0.3,0.4-0.1,0.7c0.1,0.3,2.7,5.5,6.7,7.7H0.4C0.2,8.5,0,8.7,0,9c0,0.3,0.2,0.5,0.4,0.5h23.7c-4,2.2-6.6,7.4-6.7,7.7c-0.1,0.2,0,0.5,0.1,0.7c0.2,0.1,0.4,0.1,0.6-0.2c0-0.1,4.1-8.2,9.5-8.2C27.8,9.5,28,9.3,28,9C28,8.7,27.8,8.5,27.6,8.5z" />
                                <path d="M27.6,8.5c-5.5,0-9.5-8.1-9.5-8.2C17.9,0,17.7,0,17.5,0.1c-0.2,0.1-0.3,0.4-0.1,0.7c0.1,0.3,2.7,5.5,6.7,7.7H0.4C0.2,8.5,0,8.7,0,9c0,0.3,0.2,0.5,0.4,0.5h23.7c-4,2.2-6.6,7.4-6.7,7.7c-0.1,0.2,0,0.5,0.1,0.7c0.2,0.1,0.4,0.1,0.6-0.2c0-0.1,4.1-8.2,9.5-8.2C27.8,9.5,28,9.3,28,9C28,8.7,27.8,8.5,27.6,8.5z" />
                              </svg>
                            </span>
                            <p>{data.name}</p>
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>

              <div className="col-xl-3 col-lg-3 col-md-6 col-12">
                <div className="cat-widget cat-foot-menu">
                  <h4 className="footer-title">Contact Us</h4>
                  <div className="footer-menu contact-menu">
                    <ul>
                      <li>
                        <a href="javascript:void(0);">
                          <span>
                            <svg
                              version="1.1"
                              xmlns="http://www.w3.org/2000/svg"
                              xmlnsXlink="http://www.w3.org/1999/xlink"
                              x="0px"
                              y="0px"
                              viewBox="0 0 511.999 511.999"
                              xmlSpace="preserve"
                            >
                              <g>
                                <g>
                                  <path
                                    d="M256.98,40.005c-5.53-0.04-10.04,4.41-10.08,9.93c-0.04,5.52,4.4,10.03,9.93,10.07c5.52,0.04,10.03-4.4,10.07-9.92
                                                                C266.94,44.565,262.5,40.055,256.98,40.005z"
                                  />
                                </g>
                              </g>
                              <g>
                                <g>
                                  <path
                                    d="M256.525,100.004c-38.611-0.274-70.232,30.875-70.524,69.474c-0.291,38.597,30.875,70.233,69.474,70.524
                                                                c0.179,0.001,0.357,0.002,0.536,0.002c38.353,0,69.698-31.056,69.988-69.476C326.29,131.933,295.125,100.295,256.525,100.004z
                                                                 M256.009,220.005c-0.126,0-0.258-0.001-0.384-0.002c-27.571-0.208-49.833-22.806-49.625-50.375
                                                                c0.207-27.445,22.595-49.627,49.991-49.627c0.126,0,0.258,0.001,0.384,0.002c27.571,0.208,49.833,22.806,49.625,50.375
                                                                C305.792,197.823,283.405,220.005,256.009,220.005z"
                                  />
                                </g>
                              </g>
                              <g>
                                <g>
                                  <path
                                    d="M299.631,47.589c-5.202-1.846-10.921,0.879-12.767,6.085c-1.845,5.206,0.88,10.921,6.085,12.767
                                                                c44.047,15.611,73.402,57.562,73.05,104.389c-0.041,5.522,4.402,10.033,9.925,10.075c0.025,0,0.051,0,0.077,0
                                                                c5.486,0,9.956-4.428,9.998-9.925C386.415,115.633,351.706,66.046,299.631,47.589z"
                                  />
                                </g>
                              </g>
                              <g>
                                <g>
                                  <path
                                    d="M317.357,376.442c66.513-85.615,108.08-130.26,108.641-205.164C426.702,77.035,350.22,0,255.984,0
                                                                C162.848,0,86.71,75.428,86.002,168.728c-0.572,76.935,41.767,121.519,108.739,207.7C128.116,386.384,86.002,411.401,86.002,442
                                                                c0,20.497,18.946,38.89,53.349,51.79c31.313,11.742,72.74,18.209,116.649,18.209s85.336-6.467,116.649-18.209
                                                                c34.403-12.901,53.349-31.294,53.349-51.791C425.998,411.417,383.923,386.406,317.357,376.442z M106.001,168.879
                                                                C106.625,86.55,173.8,20,255.986,20c83.159,0,150.633,67.988,150.013,151.129c-0.532,71.134-44.614,114.971-114.991,206.714
                                                                c-12.553,16.356-24.081,31.82-34.993,46.947c-10.88-15.136-22.178-30.323-34.919-46.953
                                                                C147.81,282.253,105.459,241.729,106.001,168.879z M256,492c-85.851,0-149.999-26.397-149.999-50
                                                                c0-17.504,38.348-39.616,102.826-47.273c14.253,18.701,26.749,35.691,39.005,53.043c1.872,2.65,4.914,4.228,8.159,4.23
                                                                c0.003,0,0.006,0,0.009,0c3.242,0,6.283-1.572,8.158-4.217c12.14-17.126,24.978-34.535,39.109-53.045
                                                                c64.418,7.665,102.732,29.77,102.732,47.263C405.998,465.603,341.851,492,256,492z"
                                  />
                                </g>
                              </g>
                            </svg>
                          </span>
                          <p>{contactus.address}</p>
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0);">
                          <span>
                            <svg
                              version="1.1"
                              xmlns="http://www.w3.org/2000/svg"
                              xmlnsXlink="http://www.w3.org/1999/xlink"
                              x="0px"
                              y="0px"
                              viewBox="0 0 473.806 473.806"
                              xmlSpace="preserve"
                            >
                              <g>
                                <g>
                                  <path
                                    d="M374.456,293.506c-9.7-10.1-21.4-15.5-33.8-15.5c-12.3,0-24.1,5.3-34.2,15.4l-31.6,31.5c-2.6-1.4-5.2-2.7-7.7-4
                                                                c-3.6-1.8-7-3.5-9.9-5.3c-29.6-18.8-56.5-43.3-82.3-75c-12.5-15.8-20.9-29.1-27-42.6c8.2-7.5,15.8-15.3,23.2-22.8
                                                                c2.8-2.8,5.6-5.7,8.4-8.5c21-21,21-48.2,0-69.2l-27.3-27.3c-3.1-3.1-6.3-6.3-9.3-9.5c-6-6.2-12.3-12.6-18.8-18.6
                                                                c-9.7-9.6-21.3-14.7-33.5-14.7s-24,5.1-34,14.7c-0.1,0.1-0.1,0.1-0.2,0.2l-34,34.3c-12.8,12.8-20.1,28.4-21.7,46.5
                                                                c-2.4,29.2,6.2,56.4,12.8,74.2c16.2,43.7,40.4,84.2,76.5,127.6c43.8,52.3,96.5,93.6,156.7,122.7c23,10.9,53.7,23.8,88,26
                                                                c2.1,0.1,4.3,0.2,6.3,0.2c23.1,0,42.5-8.3,57.7-24.8c0.1-0.2,0.3-0.3,0.4-0.5c5.2-6.3,11.2-12,17.5-18.1c4.3-4.1,8.7-8.4,13-12.9
                                                                c9.9-10.3,15.1-22.3,15.1-34.6c0-12.4-5.3-24.3-15.4-34.3L374.456,293.506z M410.256,398.806
                                                                C410.156,398.806,410.156,398.906,410.256,398.806c-3.9,4.2-7.9,8-12.2,12.2c-6.5,6.2-13.1,12.7-19.3,20
                                                                c-10.1,10.8-22,15.9-37.6,15.9c-1.5,0-3.1,0-4.6-0.1c-29.7-1.9-57.3-13.5-78-23.4c-56.6-27.4-106.3-66.3-147.6-115.6
                                                                c-34.1-41.1-56.9-79.1-72-119.9c-9.3-24.9-12.7-44.3-11.2-62.6c1-11.7,5.5-21.4,13.8-29.7l34.1-34.1c4.9-4.6,10.1-7.1,15.2-7.1
                                                                c6.3,0,11.4,3.8,14.6,7c0.1,0.1,0.2,0.2,0.3,0.3c6.1,5.7,11.9,11.6,18,17.9c3.1,3.2,6.3,6.4,9.5,9.7l27.3,27.3
                                                                c10.6,10.6,10.6,20.4,0,31c-2.9,2.9-5.7,5.8-8.6,8.6c-8.4,8.6-16.4,16.6-25.1,24.4c-0.2,0.2-0.4,0.3-0.5,0.5
                                                                c-8.6,8.6-7,17-5.2,22.7c0.1,0.3,0.2,0.6,0.3,0.9c7.1,17.2,17.1,33.4,32.3,52.7l0.1,0.1c27.6,34,56.7,60.5,88.8,80.8
                                                                c4.1,2.6,8.3,4.7,12.3,6.7c3.6,1.8,7,3.5,9.9,5.3c0.4,0.2,0.8,0.5,1.2,0.7c3.4,1.7,6.6,2.5,9.9,2.5c8.3,0,13.5-5.2,15.2-6.9
                                                                l34.2-34.2c3.4-3.4,8.8-7.5,15.1-7.5c6.2,0,11.3,3.9,14.4,7.3c0.1,0.1,0.1,0.1,0.2,0.2l55.1,55.1
                                                                C420.456,377.706,420.456,388.206,410.256,398.806z"
                                  />
                                  <path
                                    d="M256.056,112.706c26.2,4.4,50,16.8,69,35.8s31.3,42.8,35.8,69c1.1,6.6,6.8,11.2,13.3,11.2c0.8,0,1.5-0.1,2.3-0.2
                                                                c7.4-1.2,12.3-8.2,11.1-15.6c-5.4-31.7-20.4-60.6-43.3-83.5s-51.8-37.9-83.5-43.3c-7.4-1.2-14.3,3.7-15.6,11
                                                                S248.656,111.506,256.056,112.706z"
                                  />
                                  <path
                                    d="M473.256,209.006c-8.9-52.2-33.5-99.7-71.3-137.5s-85.3-62.4-137.5-71.3c-7.3-1.3-14.2,3.7-15.5,11
                                                                c-1.2,7.4,3.7,14.3,11.1,15.6c46.6,7.9,89.1,30,122.9,63.7c33.8,33.8,55.8,76.3,63.7,122.9c1.1,6.6,6.8,11.2,13.3,11.2
                                                                c0.8,0,1.5-0.1,2.3-0.2C469.556,223.306,474.556,216.306,473.256,209.006z"
                                  />
                                </g>
                              </g>
                            </svg>
                          </span>
                          <p>(+91) {contactus.phoneNumber}</p>
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0);">
                          <span>
                            <svg
                              enableBackground="new 0 0 512 512"
                              viewBox="0 0 512 512"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g>
                                <path d="m437.02 74.98c-48.353-48.351-112.64-74.98-181.02-74.98s-132.668 26.629-181.02 74.98c-48.351 48.352-74.98 112.64-74.98 181.02s26.629 132.668 74.98 181.02c48.352 48.351 112.64 74.98 181.02 74.98s132.667-26.629 181.02-74.98c48.351-48.352 74.98-112.64 74.98-181.02s-26.629-132.668-74.98-181.02zm-10.607 351.433c-45.519 45.519-106.039 70.587-170.413 70.587s-124.894-25.068-170.413-70.587-70.587-106.039-70.587-170.413 25.068-124.894 70.587-170.413 106.039-70.587 170.413-70.587 124.894 25.068 170.413 70.587 70.587 106.039 70.587 170.413-25.068 124.894-70.587 170.413z" />
                                <path d="m336.118 445.661c-23.082 9.763-47.478 15.131-72.618 16.024v-26.289c0-4.142-3.358-7.5-7.5-7.5s-7.5 3.358-7.5 7.5v26.286c-51.103-1.84-97.511-22.395-132.569-55.007l18.52-18.52c2.929-2.929 2.929-7.678 0-10.607s-7.678-2.929-10.607 0l-18.52 18.52c-32.612-35.057-53.166-81.465-55.006-132.568h26.287c4.142 0 7.5-3.358 7.5-7.5s-3.358-7.5-7.5-7.5h-26.29c1.802-49.93 21.536-96.786 54.925-132.654l18.605 18.605c1.464 1.465 3.384 2.197 5.303 2.197s3.839-.732 5.303-2.196c2.929-2.929 2.929-7.678 0-10.607l-18.599-18.599c8.259-7.67 17.194-14.736 26.785-21.076 3.455-2.284 4.404-6.937 2.12-10.393-2.285-3.455-6.937-4.405-10.392-2.12-62.115 41.064-99.198 109.978-99.198 184.343 0 58.987 22.971 114.443 64.681 156.153s97.166 64.681 156.153 64.681c29.809 0 58.73-5.84 85.96-17.357 3.815-1.614 5.6-6.014 3.986-9.829-1.614-3.816-6.015-5.601-9.829-3.987z" />
                                <path d="m412.153 99.847c-41.71-41.71-97.166-64.68-156.153-64.68-32.955 0-64.66 7.086-94.235 21.06-3.745 1.77-5.347 6.24-3.577 9.985 1.769 3.746 6.24 5.346 9.985 3.577 25.305-11.957 52.295-18.485 80.327-19.474v26.289c0 4.142 3.358 7.5 7.5 7.5s7.5-3.358 7.5-7.5v-26.286c51.103 1.84 97.511 22.395 132.569 55.007l-18.52 18.52c-2.929 2.929-2.929 7.678 0 10.607 1.464 1.464 3.384 2.196 5.303 2.196s3.839-.732 5.303-2.197l18.52-18.52c32.613 35.058 53.167 81.466 55.007 132.569h-26.287c-4.142 0-7.5 3.358-7.5 7.5s3.358 7.5 7.5 7.5h26.291c-1.216 33.407-10.521 66.086-27.119 94.949-7.819 13.598-17.188 26.234-27.834 37.678l-18.578-18.579c-2.929-2.929-7.678-2.929-10.607 0s-2.929 7.678 0 10.607l18.574 18.574c-10.23 9.517-21.409 18.026-33.396 25.31-3.54 2.151-4.666 6.764-2.515 10.304 1.412 2.323 3.883 3.607 6.417 3.606 1.327 0 2.67-.352 3.887-1.091 31.843-19.349 58.489-46.643 77.055-78.931 19.144-33.293 29.263-71.305 29.263-109.927 0-58.987-22.97-114.443-64.68-156.153z" />
                                <path d="m341.555 156.945-75.861 75.861c-2.937-1.408-6.225-2.199-9.694-2.199s-6.757.79-9.695 2.199l-41.056-41.055c-2.929-2.929-7.678-2.929-10.606 0-2.929 2.929-2.929 7.678 0 10.606l41.056 41.055c-1.409 2.938-2.199 6.225-2.199 9.695 0 12.407 10.093 22.5 22.5 22.5s22.5-10.094 22.5-22.5c0-3.469-.79-6.757-2.199-9.695l75.861-75.861c2.929-2.929 2.929-7.678 0-10.607-2.929-2.928-7.678-2.928-10.607.001zm-85.555 103.662c-4.136 0-7.5-3.365-7.5-7.5s3.364-7.5 7.5-7.5 7.5 3.365 7.5 7.5c0 4.136-3.364 7.5-7.5 7.5z" />
                                <path d="m282.5 342.5c4.142 0 7.5-3.358 7.5-7.5s-3.358-7.5-7.5-7.5h-53c-4.142 0-7.5 3.358-7.5 7.5s3.358 7.5 7.5 7.5z" />
                              </g>
                            </svg>
                          </span>
                          <p>{contactus.serviceHours} Hours Service</p>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-6 col-12">
                <div className="cat-widget cat-foot-menu">
                  <h4 className="footer-title">External Links</h4>

                  <div className="footer-menu">
                    <ul>
                      <li>
                        <a href="/terms-conditions">
                          <span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              xmlnsXlink="http://www.w3.org/1999/xlink"
                              x="0px"
                              y="0px"
                              width={28}
                              height={18}
                              viewBox="0 0 28 18"
                              xmlSpace="preserve"
                            >
                              <path d="M27.6,8.5c-5.5,0-9.5-8.1-9.5-8.2C17.9,0,17.7,0,17.5,0.1c-0.2,0.1-0.3,0.4-0.1,0.7c0.1,0.3,2.7,5.5,6.7,7.7H0.4C0.2,8.5,0,8.7,0,9c0,0.3,0.2,0.5,0.4,0.5h23.7c-4,2.2-6.6,7.4-6.7,7.7c-0.1,0.2,0,0.5,0.1,0.7c0.2,0.1,0.4,0.1,0.6-0.2c0-0.1,4.1-8.2,9.5-8.2C27.8,9.5,28,9.3,28,9C28,8.7,27.8,8.5,27.6,8.5z" />
                              <path d="M27.6,8.5c-5.5,0-9.5-8.1-9.5-8.2C17.9,0,17.7,0,17.5,0.1c-0.2,0.1-0.3,0.4-0.1,0.7c0.1,0.3,2.7,5.5,6.7,7.7H0.4C0.2,8.5,0,8.7,0,9c0,0.3,0.2,0.5,0.4,0.5h23.7c-4,2.2-6.6,7.4-6.7,7.7c-0.1,0.2,0,0.5,0.1,0.7c0.2,0.1,0.4,0.1,0.6-0.2c0-0.1,4.1-8.2,9.5-8.2C27.8,9.5,28,9.3,28,9C28,8.7,27.8,8.5,27.6,8.5z" />
                            </svg>
                          </span>
                          <p>Terms & Conditions </p>
                        </a>
                      </li>
                      <li>
                        <a href="/privacy-policy">
                          <span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              xmlnsXlink="http://www.w3.org/1999/xlink"
                              x="0px"
                              y="0px"
                              width={28}
                              height={18}
                              viewBox="0 0 28 18"
                              xmlSpace="preserve"
                            >
                              <path d="M27.6,8.5c-5.5,0-9.5-8.1-9.5-8.2C17.9,0,17.7,0,17.5,0.1c-0.2,0.1-0.3,0.4-0.1,0.7c0.1,0.3,2.7,5.5,6.7,7.7H0.4C0.2,8.5,0,8.7,0,9c0,0.3,0.2,0.5,0.4,0.5h23.7c-4,2.2-6.6,7.4-6.7,7.7c-0.1,0.2,0,0.5,0.1,0.7c0.2,0.1,0.4,0.1,0.6-0.2c0-0.1,4.1-8.2,9.5-8.2C27.8,9.5,28,9.3,28,9C28,8.7,27.8,8.5,27.6,8.5z" />
                              <path d="M27.6,8.5c-5.5,0-9.5-8.1-9.5-8.2C17.9,0,17.7,0,17.5,0.1c-0.2,0.1-0.3,0.4-0.1,0.7c0.1,0.3,2.7,5.5,6.7,7.7H0.4C0.2,8.5,0,8.7,0,9c0,0.3,0.2,0.5,0.4,0.5h23.7c-4,2.2-6.6,7.4-6.7,7.7c-0.1,0.2,0,0.5,0.1,0.7c0.2,0.1,0.4,0.1,0.6-0.2c0-0.1,4.1-8.2,9.5-8.2C27.8,9.5,28,9.3,28,9C28,8.7,27.8,8.5,27.6,8.5z" />
                            </svg>
                          </span>
                          <p>Privacy Policy</p>
                        </a>
                      </li>
                      <li>
                        <a href="/refund-policy">
                          <span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              xmlnsXlink="http://www.w3.org/1999/xlink"
                              x="0px"
                              y="0px"
                              width={28}
                              height={18}
                              viewBox="0 0 28 18"
                              xmlSpace="preserve"
                            >
                              <path d="M27.6,8.5c-5.5,0-9.5-8.1-9.5-8.2C17.9,0,17.7,0,17.5,0.1c-0.2,0.1-0.3,0.4-0.1,0.7c0.1,0.3,2.7,5.5,6.7,7.7H0.4C0.2,8.5,0,8.7,0,9c0,0.3,0.2,0.5,0.4,0.5h23.7c-4,2.2-6.6,7.4-6.7,7.7c-0.1,0.2,0,0.5,0.1,0.7c0.2,0.1,0.4,0.1,0.6-0.2c0-0.1,4.1-8.2,9.5-8.2C27.8,9.5,28,9.3,28,9C28,8.7,27.8,8.5,27.6,8.5z" />
                              <path d="M27.6,8.5c-5.5,0-9.5-8.1-9.5-8.2C17.9,0,17.7,0,17.5,0.1c-0.2,0.1-0.3,0.4-0.1,0.7c0.1,0.3,2.7,5.5,6.7,7.7H0.4C0.2,8.5,0,8.7,0,9c0,0.3,0.2,0.5,0.4,0.5h23.7c-4,2.2-6.6,7.4-6.7,7.7c-0.1,0.2,0,0.5,0.1,0.7c0.2,0.1,0.4,0.1,0.6-0.2c0-0.1,4.1-8.2,9.5-8.2C27.8,9.5,28,9.3,28,9C28,8.7,27.8,8.5,27.6,8.5z" />
                            </svg>
                          </span>
                          <p>Refund Policy </p>
                        </a>
                      </li>
                      <li>
                        <a href="/cancellation-policy">
                          <span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              xmlnsXlink="http://www.w3.org/1999/xlink"
                              x="0px"
                              y="0px"
                              width={28}
                              height={18}
                              viewBox="0 0 28 18"
                              xmlSpace="preserve"
                            >
                              <path d="M27.6,8.5c-5.5,0-9.5-8.1-9.5-8.2C17.9,0,17.7,0,17.5,0.1c-0.2,0.1-0.3,0.4-0.1,0.7c0.1,0.3,2.7,5.5,6.7,7.7H0.4C0.2,8.5,0,8.7,0,9c0,0.3,0.2,0.5,0.4,0.5h23.7c-4,2.2-6.6,7.4-6.7,7.7c-0.1,0.2,0,0.5,0.1,0.7c0.2,0.1,0.4,0.1,0.6-0.2c0-0.1,4.1-8.2,9.5-8.2C27.8,9.5,28,9.3,28,9C28,8.7,27.8,8.5,27.6,8.5z" />
                              <path d="M27.6,8.5c-5.5,0-9.5-8.1-9.5-8.2C17.9,0,17.7,0,17.5,0.1c-0.2,0.1-0.3,0.4-0.1,0.7c0.1,0.3,2.7,5.5,6.7,7.7H0.4C0.2,8.5,0,8.7,0,9c0,0.3,0.2,0.5,0.4,0.5h23.7c-4,2.2-6.6,7.4-6.7,7.7c-0.1,0.2,0,0.5,0.1,0.7c0.2,0.1,0.4,0.1,0.6-0.2c0-0.1,4.1-8.2,9.5-8.2C27.8,9.5,28,9.3,28,9C28,8.7,27.8,8.5,27.6,8.5z" />
                            </svg>
                          </span>
                          <p>Cancellation Policy</p>
                        </a>
                      </li>
                      <li>
                        <a href="/shipping-policy">
                          <span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              xmlnsXlink="http://www.w3.org/1999/xlink"
                              x="0px"
                              y="0px"
                              width={28}
                              height={18}
                              viewBox="0 0 28 18"
                              xmlSpace="preserve"
                            >
                              <path d="M27.6,8.5c-5.5,0-9.5-8.1-9.5-8.2C17.9,0,17.7,0,17.5,0.1c-0.2,0.1-0.3,0.4-0.1,0.7c0.1,0.3,2.7,5.5,6.7,7.7H0.4C0.2,8.5,0,8.7,0,9c0,0.3,0.2,0.5,0.4,0.5h23.7c-4,2.2-6.6,7.4-6.7,7.7c-0.1,0.2,0,0.5,0.1,0.7c0.2,0.1,0.4,0.1,0.6-0.2c0-0.1,4.1-8.2,9.5-8.2C27.8,9.5,28,9.3,28,9C28,8.7,27.8,8.5,27.6,8.5z" />
                              <path d="M27.6,8.5c-5.5,0-9.5-8.1-9.5-8.2C17.9,0,17.7,0,17.5,0.1c-0.2,0.1-0.3,0.4-0.1,0.7c0.1,0.3,2.7,5.5,6.7,7.7H0.4C0.2,8.5,0,8.7,0,9c0,0.3,0.2,0.5,0.4,0.5h23.7c-4,2.2-6.6,7.4-6.7,7.7c-0.1,0.2,0,0.5,0.1,0.7c0.2,0.1,0.4,0.1,0.6-0.2c0-0.1,4.1-8.2,9.5-8.2C27.8,9.5,28,9.3,28,9C28,8.7,27.8,8.5,27.6,8.5z" />
                            </svg>
                          </span>
                          <p>Shipping Policy</p>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="cat-footer-copyright">
          <p>Design & Develop By Digitalraiz Creative Solutions Pvt. Ltd.</p>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
