import React, { useState, useEffect } from 'react'
import { imgurl } from '../Servicescalls'
import { Link, useNavigate } from 'react-router-dom'
import Header from './Header'
import Footer from './Footer'
import { addData } from '../Servicescalls'

function Allcars() {
  const [eventss, seteventss] = useState([])
  const [gallery, setgallery] = useState([])
  const [activeTab, setActiveTab] = useState(5);
  const navigate = useNavigate();

  const handleTabClick = async (tabIndex) => {
    setActiveTab(tabIndex);
    const bodydata = {
      eventId: tabIndex._id || "All"
    }
    const resonse = await addData("home/getHomeItems", bodydata)
    var _data = resonse
    console.log(_data)
    setgallery(_data.data.socialgallery)
  };

  const GetAllDetails = async () => {
    const bodydata = {
      eventId: "All"
    }
    const resonse = await addData("home/getHomeItems", bodydata)
    var _data = resonse
    seteventss(_data.data.events)
    setgallery(_data.data.socialgallery)
  }
  const redirectdata = (data) => {
    sessionStorage.setItem("objid", data._id)
    navigate("/eventdetails")
  }

  useEffect(() => {
    GetAllDetails()
  }, [])

  return (
    <div>
      <Header />
      <div className="cat-main-wrapper">
        {/* Page Title Section */}
        <section className="cat-page-title-section">
          <div className="container">
            <div className="row">
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <div className="cat-page-title">
                  <h1>Events</h1>
                  <ul>
                    <li>
                      <a href="#">Home</a>
                    </li>
                    <li>
                      Events
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Events */}
        <section className="cat-event-wrapper cat-section-spacer">
          <div className="container">
            <div className='row'>
              {eventss.map((data, key) => (
                <div key={key} className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12">
                  <div className="cat-service-section">
                    <div className="cat-service-inner">
                      <div className="cat-service-img">
                        <img src={imgurl + data.image} alt="" />
                      </div>
                      <div className="cat-service-info">
                        <h4>{data.name} Catering</h4>
                        <div dangerouslySetInnerHTML={{
                          __html: data.description.length > 100 ? data.description.slice(0, 100) + '...' : data.description,
                        }}></div>

                        <a type='button' onClick={() => { redirectdata(data) }} className="cat-link">
                          Read More
                          <span>
                            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width={28} height={18} viewBox="0 0 28 18" xmlSpace="preserve"><path d="M27.6,8.5c-5.5,0-9.5-8.1-9.5-8.2C17.9,0,17.7,0,17.5,0.1c-0.2,0.1-0.3,0.4-0.1,0.7c0.1,0.3,2.7,5.5,6.7,7.7H0.4C0.2,8.5,0,8.7,0,9c0,0.3,0.2,0.5,0.4,0.5h23.7c-4,2.2-6.6,7.4-6.7,7.7c-0.1,0.2,0,0.5,0.1,0.7c0.2,0.1,0.4,0.1,0.6-0.2c0-0.1,4.1-8.2,9.5-8.2C27.8,9.5,28,9.3,28,9C28,8.7,27.8,8.5,27.6,8.5z" /><path d="M27.6,8.5c-5.5,0-9.5-8.1-9.5-8.2C17.9,0,17.7,0,17.5,0.1c-0.2,0.1-0.3,0.4-0.1,0.7c0.1,0.3,2.7,5.5,6.7,7.7H0.4C0.2,8.5,0,8.7,0,9c0,0.3,0.2,0.5,0.4,0.5h23.7c-4,2.2-6.6,7.4-6.7,7.7c-0.1,0.2,0,0.5,0.1,0.7c0.2,0.1,0.4,0.1,0.6-0.2c0-0.1,4.1-8.2,9.5-8.2C27.8,9.5,28,9.3,28,9C28,8.7,27.8,8.5,27.6,8.5z" /></svg>
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>

        {/* Event gallery  */}
        <section className="mt-4 mb-3">
          <div className="container">
            <div className="row">
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <div className="cat-heading-wrapper">
                  <h4>
                    Latest Events
                  </h4>
                  <h2>
                    Our Social &amp; Professional Events Gallery
                  </h2>
                </div>
              </div>
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12 col-xs">
                    <div className="cat-filter-menu">
                      <button onClick={() => handleTabClick(5)} className={`filter ${activeTab === 5 ? 'active' : ''}`} data-filter="all">All Events</button>
                      {eventss.map((data, key) => (
                        <button key={key} onClick={() => handleTabClick(data, key)} className={`filter ${activeTab === key ? 'active' : ''}`} data-filter=".wedding">{data.name}</button>
                      ))}
                      {/* <button onClick={() => handleTabClick(3)} className={`filter ${activeTab === 3 ? 'active' : ''}`} data-filter=".corporate">Corporate</button>
                      <button onClick={() => handleTabClick(4)} className={`filter ${activeTab === 4 ? 'active' : ''}`} data-filter=".cocktail">Cocktail</button>
                      <button onClick={() => handleTabClick(5)} className={`filter ${activeTab === 5 ? 'active' : ''}`} data-filter=".buffet">Buffet</button> */}
                    </div>
                  </div>
                </div>
                <div className="cat-filter" id="MixItUpB833A1">
                  <div className="row">
                    {gallery.map((data, key) => (
                      <div key={key} className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12 mix wedding" style={{ display: 'inline-block' }}>
                        <div className="cat-porfolio-section">
                          <img src={imgurl + data.image} alt="" />
                          <div className="cat-overlay">
                            <a className="popup-gallery" href={imgurl + data.image} title><span className="fa fa-search-plus" aria-hidden="true" /></a>
                            <div className="cat-overlay-text">
                              <h4>{data.title}</h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

      </div>
      <Footer />
    </div>
  )
}

export default Allcars