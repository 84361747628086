import { BrowserRouter, Routes, Route } from "react-router-dom";
import Eventdetails from "./Components/Eventdetails";
import "slick-carousel/slick/slick-theme.css";
import Contact from "./Components/Contact";
import Events from "./Components/Events";
import "slick-carousel/slick/slick.css";
import About from "./Components/About";
import "./assets/css/main-style.css";
import Home from "./Components/Home";
import Faq from "./Components/Faq";
import Termsconditions from "./Components/Termsconditions";
import Shipping from "./Components/Shipping";
import Refundpolicys from "./Components/Refundpolicys";
import Cancellationpolicy from "./Components/Cancellationpolicy";
import Privacypolicy from "./Components/Privacypolicy";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/events" element={<Events />} />
        <Route path="/eventdetails" element={<Eventdetails />} />
        <Route path="/contact" element={<Contact />} />

        <Route path="/terms-conditions" element={<Termsconditions />} />
        <Route path="/shipping-policy" element={<Shipping />} />
        <Route path="/refund-policy" element={<Refundpolicys />} />
        <Route path="/cancellation-policy" element={<Cancellationpolicy />} />
        <Route path="/privacy-policy" element={<Privacypolicy />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
