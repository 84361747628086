import React, { useState, useEffect } from 'react'
import Header from './Header'
import Footer from './Footer'
import { addData, imgurl } from '../Servicescalls'

function Eventdetails() {

    const [forms, setForms] = useState([])
    const [gallery, setgallery] = useState([])

    const getSingleevent = async () => {
        const bodydata = {
            _id: sessionStorage.getItem('objid')
        }
        const resonse = await addData("eventweb/getEvent", bodydata)
        var _data = resonse
        setForms(_data.data.eventResult)
    };
    const GetAllDetails = async () => {
        const bodydata={
          eventId:"All"
        }
        const resonse = await addData("home/getHomeItems", bodydata)
        var _data = resonse
        setgallery(_data.data.socialgallery)
      }
    

    useEffect(() => {
        getSingleevent()
        GetAllDetails()
    }, [])


    return (
        <div>
            <Header />
            <div className="cat-main-wrapper">
                <section className="cat-page-title-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <div className="cat-page-title">
                                    <h1>Event Details</h1>
                                    <ul>
                                        <li>
                                            <a href="#">Home</a>
                                        </li>
                                        <li>
                                            Event Details
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div>
                    <section className="cat-about-wrapper cat-section-spacer">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                    <div className="cat-story-img">
                                        <img src="../assets/images/main/service.jpg" alt="" />
                                    </div>
                                </div>
                                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                    <div className="cat-story-info">
                                        <div className="cat-heading-wrapper">
                                            <h2>
                                                {forms.name}
                                            </h2>
                                        </div>
                                        <div className='mb-3'
                                            dangerouslySetInnerHTML={{
                                                __html: forms.description,
                                            }}
                                        ></div>
                                        {/* <div className='row'>
                                            <div className='col-2'>
                                                <img src="../assets/images/main/icon1.png" alt="" />
                                            </div>
                                            <div className='col-10'>
                                                <b>Best Cusine</b>
                                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-2'>
                                                <img src="../assets/images/main/icon2.png" alt="" />
                                            </div>
                                            <div className='col-10'>
                                                <b>Good Rest</b>
                                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-2'>
                                                <img src="../assets/images/main/icon3.png" alt="" />
                                            </div>
                                            <div className='col-10'>
                                                <b>24 hour Support</b>
                                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                            </div>
                                        </div> */}

                                        <a
                                            // onClick={toggleMenu}
                                            type="button" className="cat-btn header-btn mt-3" data-toggle="modal" data-target="#exampleModal"
                                        >
                                            Request
                                        </a>
                                        {/* <ul className="cat-story-list">
                    <li>
                      <span>
                        <svg enableBackground="new 0 0 512 512" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><g><path d="m271.176 121.396c-150.205 7.822-271 132.495-271 284.604v106l37.925-88.29c44.854-89.692 133.847-147.041 233.075-152.314v121.318l240.648-196.714-240.648-196z" /></g></svg>
                      </span> Fresh and Fast food Delivery
                    </li>
                    <li>
                      <span>
                        <svg enableBackground="new 0 0 512 512" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><g><path d="m271.176 121.396c-150.205 7.822-271 132.495-271 284.604v106l37.925-88.29c44.854-89.692 133.847-147.041 233.075-152.314v121.318l240.648-196.714-240.648-196z" /></g></svg>
                      </span> 24/7 Customer Support
                    </li>
                    <li>
                      <span>
                        <svg enableBackground="new 0 0 512 512" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><g><path d="m271.176 121.396c-150.205 7.822-271 132.495-271 284.604v106l37.925-88.29c44.854-89.692 133.847-147.041 233.075-152.314v121.318l240.648-196.714-240.648-196z" /></g></svg>
                      </span> Easy Customization Options
                    </li>
                    <li>
                      <span>
                        <svg enableBackground="new 0 0 512 512" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><g><path d="m271.176 121.396c-150.205 7.822-271 132.495-271 284.604v106l37.925-88.29c44.854-89.692 133.847-147.041 233.075-152.314v121.318l240.648-196.714-240.648-196z" /></g></svg>
                      </span> Delicious Deals for Delicious Meals
                    </li>
                  </ul> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="cat-event-wrapper cat-section-spacer">
                        <div className="container">
                            <div className="row">

                                <div className="col-xl-6 col-lg-6 col-md-12 ">
                                    <div className="cat-heading-wrapper mt-5 pt-5">
                                        {/* <h4>
                    Latest Events
                  </h4> */}
                                        <h2>
                                            Our Social & Business Events Gallery
                                        </h2>
                                        <p className="mb-3 mt-3">
                                            Consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore eit esdioilore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullaemco laboeeiris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor iesdein reprehendeerit
                                            in voluptate velit esse cillum dolore.
                                        </p>
                                        <ul className='text-left'>
                                            <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</li>
                                            <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</li>
                                            <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</li>
                                            <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</li>
                                            <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-xl-6 col-lg-6 col-md-12">
                                    <div className="cat-filter" id="MixItUpB833A1">
                                        <div className="row">
                                            {gallery.map((data, key)=>(
                                            <div key={key} className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 mix wedding" style={{ display: 'inline-block' }}>
                                                <div className="cat-porfolio-section">
                                                    <img style={{ minHeight: "140px", maxHeight: "140px" }} src={imgurl +data.image} alt="" />
                                                    <div className="cat-overlay">
                                                        <a className="popup-gallery" href={imgurl + data.image} title><span className="fa fa-search-plus" aria-hidden="true" /></a>
                                                        <div className="cat-overlay-text">
                                                            <h4>{data.title}</h4>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                               ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Eventdetails