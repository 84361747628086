import React, { useRef, useState, useEffect } from "react";
import Slider from "react-slick";
import Header from "./Header";
import Footer from "./Footer";
import { Link, useNavigate } from "react-router-dom";
import { addData, imgurl } from "../Servicescalls";

function Home() {
  const navigate = useNavigate();
  const sliderRef = useRef(null);

  const next = () => {
    sliderRef.current.slickNext();
  };

  const previous = () => {
    sliderRef.current.slickPrev();
  };

  const sliderRef1 = useRef(null);

  const next1 = () => {
    sliderRef1.current.slickNext();
  };

  const previous1 = () => {
    sliderRef1.current.slickPrev();
  };

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          autoplay: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          autoplay: true,
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        autoplay: true,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  var settings2 = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          autoplay: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          autoplay: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        autoplay: true,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const [alldata, setalldata] = useState([]);
  const [contactus, setcontactus] = useState([]);
  const [dishes, setdishes] = useState([]);
  const [eventss, seteventss] = useState([]);
  const [testimon, settestimon] = useState([]);
  const [gallery, setgallery] = useState([]);
  const [activeTab, setActiveTab] = useState(5);

  const handleTabClick = async (tabIndex) => {
    setActiveTab(tabIndex);
    const bodydata = {
      eventId: tabIndex._id || "All",
    };
    const resonse = await addData("home/getHomeItems", bodydata);
    var _data = resonse;
    console.log(_data);
    setgallery(_data.data.socialgallery);
  };

  const GetAllDetails = async () => {
    const bodydata = {
      eventId: "All",
    };
    const resonse = await addData("home/getHomeItems", bodydata);
    var _data = resonse;
    console.log(_data);
    setalldata(_data.data);
    setcontactus(_data.data.contactus[0]);
    setdishes(_data.data.dishes);
    seteventss(_data.data.events);
    settestimon(_data.data.testimoni);
    setgallery(_data.data.socialgallery);
  };

  useEffect(() => {
    GetAllDetails();
  }, []);
  const redirectdata = (data) => {
    sessionStorage.setItem("objid", data._id);
    navigate("/eventdetails");
  };

  return (
    <div>
      <Header />
      <div className="cat-main-wrapper">
        {/* Home Banner Section */}
        <section className="cat-banner-wrapper">
          <div className="cat-banner-social">
            <ul>
              <li>
                <a href={contactus.facebook}>Facebook</a>
              </li>
              <li>
                <a href={contactus.twitter}>Twitter</a>
              </li>
              <li>
                <a href={contactus.instagram}>Instagram</a>
              </li>
            </ul>
          </div>
          <div className="container">
            <div className="cat-banner-section">
              <div className="row align-items-center">
                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                  <div className="cat-banner-text">
                    <div className="cat-banner-text-inner">
                      <h4>
                        <span>Planning Fabulous</span>
                      </h4>
                      <h1 className="cat-banner-title">
                        Book Us for your Dream Event
                      </h1>
                      <p>
                        Find out professional caterers in your city for your
                        Dream Events, long established fact a reader will be
                        distracted the readable.
                      </p>
                      <div className="cat-banner-btn-wrap">
                        <a
                          type="button"
                          className="cat-btn bookNow"
                          data-toggle="modal"
                          data-target="#exampleModal"
                        >
                          Get Quote
                        </a>
                        <Link to="/about" className="cat-btn btn-black">
                          Know More
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                  <div className="banner-img">
                    <img src="../assets/images/main/story.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <a href="#scroll-down-section" className="scroll-down-section">
            <span />
          </a>
          <a href="javascript:void(0);" className="cat-banner-contact">
            <i className="fa fa-phone" aria-hidden="true" />
            <span>+91 {contactus.phoneNumber}</span>
          </a>
        </section>

        {/* Top Dishes Section */}
        <section
          className="cat-top-dishes-wrapper cat-section-spacer relative"
          id="scroll-down-section"
        >
          <div className="top-left-shap bouncing">
            <img src="../assets/images/main/shap/tomatto.png" alt="" />
          </div>
          <div className="top-right-shap bouncing">
            <img src="../assets/images/main/shap/leaf.png" alt="" />
          </div>
          <div className="container">
            <div className="row">
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <div className="cat-heading-wrapper">
                  <h4>Offer Zone</h4>
                  <h2>Popular Dishes Of Our Caterers</h2>
                </div>
              </div>
              {dishes.map((data, key) => (
                <div key={key} className="col-lg-4 col-md-6 col-sm-6 col-12">
                  <div className="cat-top-dish-section">
                    <div className="cat-top-dish-inner">
                      <div className="cat-top-dish-img">
                        <img src={imgurl + data.image} alt="" />
                      </div>
                      <div className="cat-top-dish-info">
                        <a href="javascript:void(0);">
                          <h4>
                            {data.name} <span> ₹ {data.price}</span>
                          </h4>
                        </a>
                        <ul>
                          {data.rating === "1" ? (
                            <li>
                              {" "}
                              <i className="fa fa-star" aria-hidden="true" />
                            </li>
                          ) : data.rating === "1.5" ? (
                            <>
                              <li>
                                {" "}
                                <i className="fa fa-star" aria-hidden="true" />
                              </li>
                              <li>
                                <i
                                  className="fa fa-star-half-o"
                                  aria-hidden="true"
                                />
                              </li>
                            </>
                          ) : data.rating === "2" ? (
                            <>
                              <li>
                                {" "}
                                <i className="fa fa-star" aria-hidden="true" />
                              </li>
                              <li>
                                {" "}
                                <i className="fa fa-star" aria-hidden="true" />
                              </li>
                            </>
                          ) : data.rating === "2.5" ? (
                            <>
                              <li>
                                {" "}
                                <i className="fa fa-star" aria-hidden="true" />
                              </li>
                              <li>
                                {" "}
                                <i className="fa fa-star" aria-hidden="true" />
                              </li>
                              <li>
                                <i
                                  className="fa fa-star-half-o"
                                  aria-hidden="true"
                                />
                              </li>
                            </>
                          ) : data.rating === "3" ? (
                            <>
                              <li>
                                {" "}
                                <i className="fa fa-star" aria-hidden="true" />
                              </li>
                              <li>
                                {" "}
                                <i className="fa fa-star" aria-hidden="true" />
                              </li>
                              <li>
                                {" "}
                                <i className="fa fa-star" aria-hidden="true" />
                              </li>
                            </>
                          ) : data.rating === "3.5" ? (
                            <>
                              <li>
                                {" "}
                                <i className="fa fa-star" aria-hidden="true" />
                              </li>
                              <li>
                                {" "}
                                <i className="fa fa-star" aria-hidden="true" />
                              </li>
                              <li>
                                {" "}
                                <i className="fa fa-star" aria-hidden="true" />
                              </li>
                              <li>
                                <i
                                  className="fa fa-star-half-o"
                                  aria-hidden="true"
                                />
                              </li>
                            </>
                          ) : data.rating === "4" ? (
                            <>
                              <li>
                                {" "}
                                <i className="fa fa-star" aria-hidden="true" />
                              </li>
                              <li>
                                {" "}
                                <i className="fa fa-star" aria-hidden="true" />
                              </li>
                              <li>
                                {" "}
                                <i className="fa fa-star" aria-hidden="true" />
                              </li>
                              <li>
                                {" "}
                                <i className="fa fa-star" aria-hidden="true" />
                              </li>
                            </>
                          ) : data.rating === "4.5" ? (
                            <>
                              <li>
                                {" "}
                                <i className="fa fa-star" aria-hidden="true" />
                              </li>
                              <li>
                                {" "}
                                <i className="fa fa-star" aria-hidden="true" />
                              </li>
                              <li>
                                {" "}
                                <i className="fa fa-star" aria-hidden="true" />
                              </li>
                              <li>
                                {" "}
                                <i className="fa fa-star" aria-hidden="true" />
                              </li>
                              <li>
                                <i
                                  className="fa fa-star-half-o"
                                  aria-hidden="true"
                                />
                              </li>
                            </>
                          ) : data.rating === "5" ? (
                            <>
                              <li>
                                {" "}
                                <i className="fa fa-star" aria-hidden="true" />
                              </li>
                              <li>
                                {" "}
                                <i className="fa fa-star" aria-hidden="true" />
                              </li>
                              <li>
                                {" "}
                                <i className="fa fa-star" aria-hidden="true" />
                              </li>
                              <li>
                                {" "}
                                <i className="fa fa-star" aria-hidden="true" />
                              </li>
                              <li>
                                {" "}
                                <i className="fa fa-star" aria-hidden="true" />
                              </li>
                            </>
                          ) : null}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
        {/* Service Section */}
        <section className="cat-service-wrapper cat-section-spacer-equal has-bg relative">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <div className="cat-heading-wrapper">
                  <h4>Our Services</h4>
                  <h2>What We Offer</h2>
                </div>
              </div>
              {eventss.map((data, key) => (
                <div
                  key={key}
                  className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12"
                >
                  <div className="cat-service-section">
                    <div className="cat-service-inner">
                      <div className="cat-service-img">
                        <img src={imgurl + data.image} alt="" />
                      </div>
                      <div className="cat-service-info">
                        <h4>{data.name} Catering</h4>
                        <div
                          dangerouslySetInnerHTML={{
                            __html:
                              data.description.length > 100
                                ? data.description.slice(0, 100) + "..."
                                : data.description,
                          }}
                        ></div>
                        <a
                          type="button"
                          onClick={() => {
                            redirectdata(data);
                          }}
                          className="cat-link"
                        >
                          Read More
                          <span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              xmlnsXlink="http://www.w3.org/1999/xlink"
                              x="0px"
                              y="0px"
                              width={28}
                              height={18}
                              viewBox="0 0 28 18"
                              xmlSpace="preserve"
                            >
                              <path d="M27.6,8.5c-5.5,0-9.5-8.1-9.5-8.2C17.9,0,17.7,0,17.5,0.1c-0.2,0.1-0.3,0.4-0.1,0.7c0.1,0.3,2.7,5.5,6.7,7.7H0.4C0.2,8.5,0,8.7,0,9c0,0.3,0.2,0.5,0.4,0.5h23.7c-4,2.2-6.6,7.4-6.7,7.7c-0.1,0.2,0,0.5,0.1,0.7c0.2,0.1,0.4,0.1,0.6-0.2c0-0.1,4.1-8.2,9.5-8.2C27.8,9.5,28,9.3,28,9C28,8.7,27.8,8.5,27.6,8.5z" />
                              <path d="M27.6,8.5c-5.5,0-9.5-8.1-9.5-8.2C17.9,0,17.7,0,17.5,0.1c-0.2,0.1-0.3,0.4-0.1,0.7c0.1,0.3,2.7,5.5,6.7,7.7H0.4C0.2,8.5,0,8.7,0,9c0,0.3,0.2,0.5,0.4,0.5h23.7c-4,2.2-6.6,7.4-6.7,7.7c-0.1,0.2,0,0.5,0.1,0.7c0.2,0.1,0.4,0.1,0.6-0.2c0-0.1,4.1-8.2,9.5-8.2C27.8,9.5,28,9.3,28,9C28,8.7,27.8,8.5,27.6,8.5z" />
                            </svg>
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
        {/* Booking */}

        {/* Video */}
        <section className="cat-video-wrapper has-bg">
          <div className="container-fluid">
            <div className="row align-items-center">
              <div className="col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12 p-0">
                <div className="cat-story-video cat-main-video">
                  <img
                    className="video-img"
                    src="../assets/images/main/banner-bg.jpg"
                    alt=""
                  />
                  <div className="cat-play-btn">
                    <a
                      className="popup-youtube"
                      rel="external"
                      href="https://www.youtube.com/watch?v=jKabrDJW2EU"
                    >
                      <i className="fa fa-play" aria-hidden="true" />
                    </a>
                    <div className="btn-wave" />
                  </div>
                </div>
              </div>
              <div className="col-xl-7 col-lg-7 col-md-12 col-sm-12 col-12">
                <div className="cat-counter-info">
                  <div className="cat-counter-wrap">
                    <div className="row">
                      <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-6 counter1">
                        <div className="cat-counter-section">
                          <div className="cat-counter-section-inner">
                            <span>
                              <img
                                src="https://kamleshyadav.com/html/catering/html/assets/images/main/c1.svg"
                                alt=""
                              />
                            </span>
                            <h4>786</h4>
                            <p>Happy Customers</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-6 counter2">
                        <div className="cat-counter-section">
                          <div className="cat-counter-section-inner">
                            <span>
                              <img
                                src="https://kamleshyadav.com/html/catering/html/assets/images/main/c2.svg"
                                alt=""
                              />
                            </span>
                            <h4>109</h4>
                            <p>Expert Chefs</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-6 counter3">
                        <div className="cat-counter-section">
                          <div className="cat-counter-section-inner">
                            <span>
                              <img
                                src="https://kamleshyadav.com/html/catering/html/assets/images/main/c3.svg"
                                alt=""
                              />
                            </span>
                            <h4>23</h4>
                            <p>Years Of Experience</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-6 counter4">
                        <div className="cat-counter-section">
                          <div className="cat-counter-section-inner">
                            <span>
                              <img
                                src="https://kamleshyadav.com/html/catering/html/assets/images/main/c4.svg"
                                alt=""
                              />
                            </span>
                            <h4>235</h4>
                            <p>Events Completed</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Story */}
        <section className="cat-about-wrapper cat-section-spacer">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                <div className="cat-story-img">
                  <img src="../assets/images/main/story.png" alt="" />
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                <div className="cat-story-info">
                  <div className="cat-heading-wrapper">
                    <h4>Our Story</h4>
                    <h2>Trusted By 200 + satisfied clients</h2>
                  </div>
                  <p className="mb-3">
                    Food Delivery! We are dedicated to bringing you delicious
                    meals right to your doorstep. Our mission is to provide
                    high-quality food and exceptional service. Whether you're
                    craving local cuisine or something more exotic, we have a
                    wide variety of options to satisfy your taste buds. Our team
                    is passionate about food and committed to ensuring your
                    dining experience is seamless and enjoyable. Thank you for
                    choosing Hasini Enterprises' Food Delivery.
                  </p>
                  <ul className="cat-story-list">
                    <li>
                      <span>
                        <svg
                          enableBackground="new 0 0 512 512"
                          viewBox="0 0 512 512"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g>
                            <path d="m271.176 121.396c-150.205 7.822-271 132.495-271 284.604v106l37.925-88.29c44.854-89.692 133.847-147.041 233.075-152.314v121.318l240.648-196.714-240.648-196z" />
                          </g>
                        </svg>
                      </span>{" "}
                      Fresh and Fast food Delivery
                    </li>
                    <li>
                      <span>
                        <svg
                          enableBackground="new 0 0 512 512"
                          viewBox="0 0 512 512"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g>
                            <path d="m271.176 121.396c-150.205 7.822-271 132.495-271 284.604v106l37.925-88.29c44.854-89.692 133.847-147.041 233.075-152.314v121.318l240.648-196.714-240.648-196z" />
                          </g>
                        </svg>
                      </span>{" "}
                      24/7 Customer Support
                    </li>
                    <li>
                      <span>
                        <svg
                          enableBackground="new 0 0 512 512"
                          viewBox="0 0 512 512"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g>
                            <path d="m271.176 121.396c-150.205 7.822-271 132.495-271 284.604v106l37.925-88.29c44.854-89.692 133.847-147.041 233.075-152.314v121.318l240.648-196.714-240.648-196z" />
                          </g>
                        </svg>
                      </span>{" "}
                      Easy Customization Options
                    </li>
                    <li>
                      <span>
                        <svg
                          enableBackground="new 0 0 512 512"
                          viewBox="0 0 512 512"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g>
                            <path d="m271.176 121.396c-150.205 7.822-271 132.495-271 284.604v106l37.925-88.29c44.854-89.692 133.847-147.041 233.075-152.314v121.318l240.648-196.714-240.648-196z" />
                          </g>
                        </svg>
                      </span>{" "}
                      Delicious Deals for Delicious Meals
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Events */}
        <section className="cat-event-wrapper cat-section-spacer has-bg">
          <div className="container">
            <div className="row">
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <div className="cat-heading-wrapper">
                  <h4>Latest Events</h4>
                  <h2>Our Social &amp; Professional Events Gallery</h2>
                </div>
              </div>
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12 col-xs">
                    <div className="cat-filter-menu">
                      <button
                        onClick={() => handleTabClick(5)}
                        className={`filter ${activeTab === 5 ? "active" : ""}`}
                        data-filter="all"
                      >
                        All Events
                      </button>
                      {eventss.map((data, key) => (
                        <button
                          key={key}
                          onClick={() => handleTabClick(data, key)}
                          className={`filter ${
                            activeTab === key ? "active" : ""
                          }`}
                          data-filter=".wedding"
                        >
                          {data.name}
                        </button>
                      ))}
                      {/* <button onClick={() => handleTabClick(3)} className={`filter ${activeTab === 3 ? 'active' : ''}`} data-filter=".corporate">Corporate</button>
                      <button onClick={() => handleTabClick(4)} className={`filter ${activeTab === 4 ? 'active' : ''}`} data-filter=".cocktail">Cocktail</button>
                      <button onClick={() => handleTabClick(5)} className={`filter ${activeTab === 5 ? 'active' : ''}`} data-filter=".buffet">Buffet</button> */}
                    </div>
                  </div>
                </div>
                <div className="cat-filter" id="MixItUpB833A1">
                  <div className="row">
                    {gallery.map((data, key) => (
                      <div
                        key={key}
                        className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12 mix wedding"
                        style={{ display: "inline-block" }}
                      >
                        <div className="cat-porfolio-section">
                          <img src={imgurl + data.image} alt="" />
                          <div className="cat-overlay">
                            <a
                              className="popup-gallery"
                              href={imgurl + data.image}
                              title
                            >
                              <span
                                className="fa fa-search-plus"
                                aria-hidden="true"
                              />
                            </a>
                            <div className="cat-overlay-text">
                              <h4>{data.title}</h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Mobile App Section */}
        <section className="cat-mobile-section cat-section-spacer-equal relative">
          <div className="top-left-shap bouncing">
            <img src="../assets/images/main/shap/03.png" alt="" />
          </div>
          <div className="top-right-shap bouncing">
            <img src="../assets/images/main/shap/01.png" alt="" />
          </div>
          <div className="container">
            <div className="row">
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <div className="cat-heading-wrapper">
                  <h4>Website</h4>
                  <h2>Book With Our Website</h2>
                </div>
              </div>
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <div className="cat-monile-app-info">
                  <div className="cat-store-imgs">
                    <a
                      type="button"
                      className="cat-btn bookNow"
                      data-toggle="modal"
                      data-target="#exampleModal"
                    >
                      Get Quote
                    </a>
                    {/* <img src="../assets/images/main/playstore.png" alt="" />
                    <img src="../assets/images/main/apple.png" alt="" /> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Team  */}

        {/* Testimonials  */}
        <section className="cat-testimonials-wrapper cat-section-spacer has-bg">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <div className="cat-heading-wrapper">
                  <h4>Our Testimonials</h4>
                  <h2>What Our Customers says!</h2>
                </div>
              </div>
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <div className="">
                  <div className="mt-3">
                    <div className="swiper-container testimonial-slider">
                      <div className="swiper-wrapper">
                        <Slider {...settings2}>
                          {testimon.map((data, key) => (
                            <div key={key} className="swiper-slide p-3">
                              <div className="mlf-testimonials-section">
                                <div className="mlf-testimonials-inner">
                                  <div className="mlf-testimonials-info">
                                    <div className="mlf-testimonials">
                                      <img src={imgurl + data.image} alt="" />
                                      <div>
                                        <h4>{data.name}</h4>
                                        <p> {data.designation}</p>
                                      </div>
                                    </div>
                                    <p>{data.description}</p>
                                    <div className="cat-rating">
                                      <ul>
                                        {data.rating === "1" ? (
                                          <li>
                                            {" "}
                                            <i
                                              className="fa fa-star"
                                              aria-hidden="true"
                                            />
                                          </li>
                                        ) : data.rating === "1.5" ? (
                                          <>
                                            <li>
                                              {" "}
                                              <i
                                                className="fa fa-star"
                                                aria-hidden="true"
                                              />
                                            </li>
                                            <li>
                                              <i
                                                className="fa fa-star-half-o"
                                                aria-hidden="true"
                                              />
                                            </li>
                                          </>
                                        ) : data.rating === "2" ? (
                                          <>
                                            <li>
                                              {" "}
                                              <i
                                                className="fa fa-star"
                                                aria-hidden="true"
                                              />
                                            </li>
                                            <li>
                                              {" "}
                                              <i
                                                className="fa fa-star"
                                                aria-hidden="true"
                                              />
                                            </li>
                                          </>
                                        ) : data.rating === "2.5" ? (
                                          <>
                                            <li>
                                              {" "}
                                              <i
                                                className="fa fa-star"
                                                aria-hidden="true"
                                              />
                                            </li>
                                            <li>
                                              {" "}
                                              <i
                                                className="fa fa-star"
                                                aria-hidden="true"
                                              />
                                            </li>
                                            <li>
                                              <i
                                                className="fa fa-star-half-o"
                                                aria-hidden="true"
                                              />
                                            </li>
                                          </>
                                        ) : data.rating === "3" ? (
                                          <>
                                            <li>
                                              {" "}
                                              <i
                                                className="fa fa-star"
                                                aria-hidden="true"
                                              />
                                            </li>
                                            <li>
                                              {" "}
                                              <i
                                                className="fa fa-star"
                                                aria-hidden="true"
                                              />
                                            </li>
                                            <li>
                                              {" "}
                                              <i
                                                className="fa fa-star"
                                                aria-hidden="true"
                                              />
                                            </li>
                                          </>
                                        ) : data.rating === "3.5" ? (
                                          <>
                                            <li>
                                              {" "}
                                              <i
                                                className="fa fa-star"
                                                aria-hidden="true"
                                              />
                                            </li>
                                            <li>
                                              {" "}
                                              <i
                                                className="fa fa-star"
                                                aria-hidden="true"
                                              />
                                            </li>
                                            <li>
                                              {" "}
                                              <i
                                                className="fa fa-star"
                                                aria-hidden="true"
                                              />
                                            </li>
                                            <li>
                                              <i
                                                className="fa fa-star-half-o"
                                                aria-hidden="true"
                                              />
                                            </li>
                                          </>
                                        ) : data.rating === "4" ? (
                                          <>
                                            <li>
                                              {" "}
                                              <i
                                                className="fa fa-star"
                                                aria-hidden="true"
                                              />
                                            </li>
                                            <li>
                                              {" "}
                                              <i
                                                className="fa fa-star"
                                                aria-hidden="true"
                                              />
                                            </li>
                                            <li>
                                              {" "}
                                              <i
                                                className="fa fa-star"
                                                aria-hidden="true"
                                              />
                                            </li>
                                            <li>
                                              {" "}
                                              <i
                                                className="fa fa-star"
                                                aria-hidden="true"
                                              />
                                            </li>
                                          </>
                                        ) : data.rating === "4.5" ? (
                                          <>
                                            <li>
                                              {" "}
                                              <i
                                                className="fa fa-star"
                                                aria-hidden="true"
                                              />
                                            </li>
                                            <li>
                                              {" "}
                                              <i
                                                className="fa fa-star"
                                                aria-hidden="true"
                                              />
                                            </li>
                                            <li>
                                              {" "}
                                              <i
                                                className="fa fa-star"
                                                aria-hidden="true"
                                              />
                                            </li>
                                            <li>
                                              {" "}
                                              <i
                                                className="fa fa-star"
                                                aria-hidden="true"
                                              />
                                            </li>
                                            <li>
                                              <i
                                                className="fa fa-star-half-o"
                                                aria-hidden="true"
                                              />
                                            </li>
                                          </>
                                        ) : data.rating === "5" ? (
                                          <>
                                            <li>
                                              {" "}
                                              <i
                                                className="fa fa-star"
                                                aria-hidden="true"
                                              />
                                            </li>
                                            <li>
                                              {" "}
                                              <i
                                                className="fa fa-star"
                                                aria-hidden="true"
                                              />
                                            </li>
                                            <li>
                                              {" "}
                                              <i
                                                className="fa fa-star"
                                                aria-hidden="true"
                                              />
                                            </li>
                                            <li>
                                              {" "}
                                              <i
                                                className="fa fa-star"
                                                aria-hidden="true"
                                              />
                                            </li>
                                            <li>
                                              {" "}
                                              <i
                                                className="fa fa-star"
                                                aria-hidden="true"
                                              />
                                            </li>
                                          </>
                                        ) : null}
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </Slider>
                      </div>
                    </div>
                    <div className="swiper-pagination pagination-testimonial-swiper" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer data={contactus} data1={gallery} />
      </div>
    </div>
  );
}

export default Home;
