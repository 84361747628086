import React from "react";
import Header from "./Header";
import Footer from "./Footer";

function Faq() {
  return (
    <div>
      <Header />
      <div className="cat-main-wrapper">
        <section className="cat-page-title-section">
          <div className="container">
            <div className="row">
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <div className="cat-page-title">
                  <h1>Cancellation Policy</h1>
                  <ul>
                    <li>
                      <a href="#">Home</a>
                    </li>
                    <li>Cancellation Policy</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="cat-faq-wrapper cat-section-spacer">
          <div className="container">
            <div className="row">
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"></div>
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <div className="cat-faq-page-ques">
                  <h5>Cancellation Timeframe</h5>
                  <p>
                    Orders can be cancelled within 10 minutes of placing the
                    order.
                  </p>
                  <h5 className="pt-4">Cancellation Process</h5>
                  <p>
                    To cancel an order, log in to your account and follow the
                    cancellation process.
                  </p>
                  <h5 className="pt-4">Non-Cancellable Orders</h5>
                  <p>
                    Orders cannot be cancelled once they are being prepared for
                    delivery.
                  </p>
                  <h5 className="pt-4">Contact Information</h5>
                  <p>
                    For cancellation-related queries, please contact us at{" "}
                    <b>info@hasinienterprises.com</b>{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
}

export default Faq;
