import React from "react";
import Header from "./Header";
import Footer from "./Footer";

function Faq() {
  return (
    <div>
      <Header />
      <div className="cat-main-wrapper">
        <section className="cat-page-title-section">
          <div className="container">
            <div className="row">
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <div className="cat-page-title">
                  <h1>Refund Policy</h1>
                  <ul>
                    <li>
                      <a href="#">Home</a>
                    </li>
                    <li>Refund Policy</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="cat-faq-wrapper cat-section-spacer">
          <div className="container">
            <div className="row">
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"></div>
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <div className="cat-faq-page-ques">
                  <h5>Eligibility for Refunds </h5>
                  <p>
                    Refunds are considered in cases of incorrect or damaged
                    orders.
                  </p>
                  <h5 className="pt-4">Refund Process</h5>
                  <p>
                    To request a refund, contact us within 24 hours of delivery.
                    Provide your order details and reason for the refund
                    request.
                  </p>
                  <h5 className="pt-4">Processing Time</h5>
                  <p>
                    Refunds will be processed within 7-10 business days to the
                    original payment method.
                  </p>
                  <h5 className="pt-4">Contact Information</h5>
                  <p>
                    For refund-related queries, please contact us at{" "}
                    <b>info@hasinienterprises.com</b>{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
}

export default Faq;
